import { writable } from 'svelte/store';
// import { debugLog } from '$lib/utils';
import { browser } from '$app/environment';

const stored = browser && localStorage.getItem('dateStore');

const { subscribe, set, update } = writable((stored && new Date(Date.parse(stored))) || new Date());

export const dateStore = {
	subscribe,
	set,
	adjustDate: (offsetDays: number) =>
		update((d) => new Date(d.getTime() + offsetDays * 24 * 60 * 60 * 1000))
};

subscribe((value) => {
	browser && localStorage.setItem('dateStore', value.toString());
});
