import {
	addDoc,
	collection,
	doc,
	orderBy,
	query,
	DocumentSnapshot,
	type SnapshotOptions,
	getDocs,
	getDoc,
	onSnapshot,
	type Unsubscribe,
	setDoc,
	type FirestoreDataConverter,
	deleteDoc
} from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import type { Ingredient, Meal } from '../types';
import { debugLog } from '$lib/utils';

export const defaultMeals = [
	{ id: '-1', name: 'Go Out / Order In', createdOn: new Date() },
	{ id: '-2', name: 'Leftovers', createdOn: new Date() },
	{ id: '-3', name: 'Other', createdOn: new Date() }
];

export const demoMeals: Meal[] = [
	{
		name: 'Pizza',
		createdOn: new Date(),
		ingredients: [
			{ product: 'Pizza dough', amount: '1 lb' },
			{ product: 'Mozzarella cheese', amount: '1/2 lb' },
			{ product: 'Tomato sauce', amount: '1 jar' },
			{ product: 'Salad', amount: '1/2 bag' }
		]
	},
	{
		name: 'Hamburgers',
		createdOn: new Date(),
		ingredients: [
			{ product: 'Ground beef', amount: '1 lb' },
			{ product: 'Buns', amount: '2' },
			{ product: 'Lettuce', amount: '1/2 head' },
			{ product: 'Tomato', amount: '1' },
			{ product: 'Ketchup', amount: '1 tbsp' },
			{ product: 'Corn', amount: '2 cobs' }
		]
	},
	{
		name: 'Spaghetti',
		createdOn: new Date(),
		ingredients: [
			{ product: 'Spaghetti', amount: '1 box' },
			{ product: 'Pasta sauce', amount: '1 jar' },
			{ product: 'Mozzarella cheese', amount: '1/4 lb' },
			{ product: 'Onion', amount: '1' },
			{ product: 'Garlic', amount: '1 clove' },
			{ product: 'Parmesan cheese', amount: '3 tbsp' },
			{ product: 'Frozen Meatballs', amount: '1/2 lb' }
		]
	},
	{
		name: 'Pigs in a blanket',
		createdOn: new Date(),
		ingredients: [
			{ product: 'Hot dogs', amount: '8' },
			{ product: 'Crescent rolls', amount: '1 roll' },
			{ product: 'Pasta side', amount: '1' },
			{ product: 'Baked beans', amount: '1 can' }
		]
	}
];

/* The converter is responsible for capturing the Firestore document ids and storing them
   with the data, and then removing it from the data before saving back to Firebase
*/
const MealConverter: FirestoreDataConverter<Meal> = {
	toFirestore: (meal: Meal) => {
		return {
			name: meal.name,
			// Firebase expects a Timestamp object, which is not a Date object.
			createdOn: Timestamp.fromDate(meal.createdOn),
			description: meal.description || '',
			url: meal.url || '',
			ingredients: meal.ingredients?.map((ingredient: Ingredient) => ({
				amount: ingredient.amount,
				product: ingredient.product
			}))
		};
	},
	fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions | undefined) => {
		const data = snapshot.data(options);
		return {
			id: snapshot.id,
			name: data?.name,
			// Firebase returns a Timestamp object, which is not a Date object.
			createdOn: data?.createdOn.toDate(),
			description: data?.description || '',
			url: data?.url || '',
			ingredients: data?.ingredients?.map((ingredient: Ingredient, index: number) => ({
				id: index,
				amount: ingredient.amount,
				product: ingredient.product
			}))
		};
	}
};

const getMeals = async (placeId: string) => {
	const basePath = `places/${placeId}/meals`;
	const ref = collection(db, basePath).withConverter(MealConverter);

	const q = query(ref, orderBy('name', 'asc'));
	const querySnapshot = await getDocs(q);
	debugLog(`Found ${querySnapshot.size} meals for ${placeId}`);
	const meals = querySnapshot.docs.map((doc) => doc.data() as Meal);
	meals.push(...defaultMeals);
	return meals;
};

const upsertMeal = async (placeId: string, meal: Meal) => {
	debugLog('Upserting meal for ' + placeId, meal);
	const basePath = `places/${placeId}/meals`;
	if (meal.id) {
		if (defaultMeals.find((m) => m.id === meal.id)) {
			console.warn('Cannot update default meal', meal);
			return meal;
		}
		const ref = doc(db, basePath + '/' + meal.id).withConverter(MealConverter);
		await setDoc(ref, meal);
		return meal;
	} else {
		const collectionRef = collection(db, basePath).withConverter(MealConverter);
		const docRef = await addDoc(collectionRef, meal);
		const docSnapshot = await getDoc(docRef);
		return docSnapshot.data() as Meal;
	}
};

const deleteMeal = async (placeId: string, mealId: string) => {
	debugLog('Deleting meal for ' + placeId, mealId);
	const basePath = `places/${placeId}/meals/${mealId}`;
	const ref = doc(db, basePath);
	return deleteDoc(ref);
};

const listenForMeals = (placeId: string, callback: (meals: Meal[]) => void) => {
	try {
		const basePath = `places/${placeId}/meals`;
		const ref = collection(db, basePath).withConverter(MealConverter);
		const q = query(ref, orderBy('name', 'asc'));
		const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
			const meals = querySnapshot.docs.map((doc) => doc.data() as Meal);
			meals.push(...defaultMeals);
			callback(meals);
		});
		return unsubscribe;
	} catch (error) {
		console.error('api/meals: Error listening for meals', error);
	}
};

export { getMeals, upsertMeal, deleteMeal, listenForMeals };
